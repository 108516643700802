<template>
<div id="content">
  <div id="content-text">
    <div v-for="item,index in list" :key="index">
      <div class="content-title">
        <h1>{{ item.title }}</h1>
        <span>{{ item.date }}</span>
      </div>
      <div class="content-content" v-for="content,index in item.content" :key="index">
        <h2>{{ content.subTitle }}</h2>
        <p v-for="conTxt,index in content.txt" :key="index">
          {{ conTxt }}
        </p>
        <div class="content-pic">
          <img v-for="pic,index in content.pic" :key="index" :src="pic"/>
        </div>
        <i>{{ content.picTxt }}</i>
      </div>
    </div>
    <div class="content-btn">
      <div @click="scraper('prev')">{{ prevTitle }}</div>
      <div @click="scraper('next')">{{ nextTitle }}</div>
    </div>
  </div>
</div>
</template>

<script>

export default {
  
  data() {
    return {
      list: [],
      // 上一条
      prevTitle: String,
      // 下一条
      nextTitle: String
    }
  },
  beforeMount() {
    let id = parseInt(this.$route.params.id);
    let obj = this.$store.state.noticList[id];
    this.list.push({
      title: obj.title,
      date: obj.date,
      content: obj.content
    });
    window.document.title = obj.title + '-核创能源科技';
    let obj1 = this.$store.state.noticList;
    if (id == 0) {
      this.prevTitle = "已是首条";
      this.nextTitle = "下一条:" + obj1[id + 1].title;
    } else if (id == obj1.length - 1) {
      this.prevTitle = "上一条:" + obj1[id - 1].title;
      this.nextTitle = "已是尾条";
    } else {
      this.prevTitle = "上一条:" + obj1[id - 1].title;
      this.nextTitle = "下一条:" + obj1[id + 1].title;
    }
  },
  methods: {
    scraper(str) {
      let id = parseInt(this.$route.params.id);
      let obj1 = this.$store.state.noticList;
      if (str == 'prev') {
        if (id == 0) {
          return;
        } else {
          this.$router.push('/notic/' + (id - 1));
        }
      } else if (str == 'next') {
        if (id == obj1.length - 1) {
          return;
        } else {
          this.$router.push('/notic/' + (id + 1));
        }
      }
    }  
  }
}
</script>

<style>
div#content  div.content-title {
  display: block;
  border-bottom: 1px solid #999999;
  padding-bottom: 5px;
}
div#content  div.content-title::after {
  content: "";
	clear: both;
	content: '';
	display: block;
	width: 0;
	height: 0;
	visibility: hidden;
}
div#content div.content-title > h1 {
  width: 100%;
  font-size: 1.5rem;
  float: left;
  font-weight: bold;
  line-height: 2.8125rem;
  margin-bottom: 0.625rem;
  color: #323232;
  display: block;
}
div#content div.content-title > span {
  display: block;
  float: left;
  color: #999999;
  font-style: normal;
  font-size: 0.875rem;
}  
div#content div.content-content {
  margin: 0 auto;
  color: #666;
  display: block;  
}
div#content div.content-content > h2 {
line-height: 150%;
font-size: 20px;
font-weight: bolder;
text-indent: 2em;
margin: 20px 0 10px 0;
}
div#content div.content-content > p {
  font-size: 14px;
  line-height: 240%;
  text-indent: 1.75em;
}
div#content div.content-content > div.content-pic {
  width: 80%;
  display: flex;
  -ms-flex-align: center;
  -ms-flex-item-align: center;
  margin: 0 auto;
  padding: 5px;
  text-align: center;
  justify-content: center;
  align-items: center;
}
div#content div.content-content > div.content-pic > img {
  width: 70%;
  display: flex;
  margin: 0 auto;
  float: left;
  margin: 10px;
  -ms-flex-align: center;
  -ms-flex-item-align: center;
  text-align: center;
  }
div#content div.content-content > i {
  text-align: center;
  display: block;
  margin: 0 auto;
  margin: 5px;
  color: #222;
  font-size: 12px;
}
div#content-text > div.content-btn {
  float: right;
  color: #666;
  margin: 5rem 0;
}
div#content-text > div.content-btn > div {
  line-height: 25px;
  height: 25px;
}
div#content-text > div.content-btn > div:hover {
  cursor: pointer;
}
</style>